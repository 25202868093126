<template>
  <a-spin :spinning="dataLoading">
    <h4>
      账单总金额：￥<span>{{ downPaymentDetail.billAmount }}</span>
    </h4>
    <h4>
      支付状态：<span>{{ downPaymentDetail.payStatusName }}</span>
    </h4>
    <a-divider />
    <h3>账单明细</h3>
    <div class="ffr">
      <a-button
        style="margin-right: 16px"
        @click="adjustBill"
        type="primary"
        v-if="isCanEdit && downPaymentDetail.billStatus != 2"
        >调整账单</a-button
      >
      <a-button
        style="margin-right: 16px"
        @click="viewAdjustmentRecords"
        :loading="recordsLoading"
        type="primary"
        >查看调整记录</a-button
      >
    </div>
    <div style="margin-top:16px;">
      <a-table
        rowKey="id"
        :columns="columns"
        :dataSource="downPaymentTable"
        :scroll="{ x: 800 }"
        :pagination="false"
      >
      </a-table>
    </div>
    <div
      class="ffr"
      v-if="downPaymentDetail.depositPayAmount"
      style="margin-top:16px;"
    >
      <h4>
        定金支付：￥<span>{{ downPaymentDetail.depositPayAmount }}</span
        >（<span>{{ downPaymentDetail.depositCode }}</span
        >）
      </h4>
    </div>
    <div class="ffr" style="margin-top:16px;">
      <h4>
        剩余需支付：￥<span>{{ downPaymentDetail.needPaymentAmount }}</span>
      </h4>
    </div>
    <!-- 调整 -->
    <a-modal
      title="调整"
      :width="850"
      :visible="isViewAdjustBill"
      :confirmLoading="editAdjustBillLoading"
      destroyOnClose
      @cancel="cancelViewAdjustBill"
      @ok="handleSaveAdjustBill"
    >
      <a-form layout="inline">
        <a-row
          :gutter="5"
          style="line-height: 3"
          v-for="item in editObj"
          :key="item.id"
        >
          <a-col :md="8" :sm="8" class="fr">
            <a-form-item :label="item.typeName">
              <a-input
                v-model="item.totalAmount"
                style="width: 100px"
                class="nob"
                placeholder="请输入"
                @change="inputChange(item.id)"
              />
            </a-form-item>
          </a-col>
          <a-col :md="16" :sm="16">
            <a-form-item label="调整备注:">
              <a-input
                v-model="item.remark"
                style="width: 300px"
                class="nob"
                placeholder="请输入"
                @change="inputChange(item.id)"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <!-- 查看明细 -->
    <a-modal
      title="查看明细"
      :width="1200"
      destroyOnClose
      @cancel="cancelAdjustDetail"
      :visible="isViewAdjustDetail"
      footer=""
    >
      <a-table
        rowKey="id"
        :columns="detailColumns"
        :dataSource="detailData"
        @change="changePageSize"
        :scroll="{ x: 800 }"
      >
        <span slot="event" slot-scope="text, record">
          <template>
            <span
              >调整前：{{ record.oldAmount }} --- 调整后：{{
                record.newAmount
              }}</span
            >
          </template>
        </span>
        <span slot="time" slot-scope="text, record">
          <template>
            <span>{{ moment(record.gmtCreated).format("YYYY-MM-DD") }}</span>
          </template>
        </span>
      </a-table>
    </a-modal>
  </a-spin>
</template>

<script>
import moment from "moment";
  

export default {
  name: "DownPaymentDetail",
  props: {
    downPaymentDetail: {},
    // 列表
    downPaymentTable: {
      type: Array,
      default: () => []
    },
    // 权限
    isCanEdit: {
      type: Boolean,
      default: () => false
    },
    // init
    initTable: {
      type: Function,
      default: null
    }
  },
  components: {},
  data() {
    return {
      dataLoading: false,
      recordsLoading: false,
      columns: [
        {
          title: "费用类型",
          dataIndex: "typeName",
          key: "typeName"
        },
        {
          title: "金额",
          dataIndex: "totalAmount",
          key: "totalAmount"
        }
      ],
      data: [],
      // 调整
      editObj: [],
      // 调整列表
      editArray: [],
      // handleTypeList
      handleTypeList: [
        { id: 1, name: "返还" },
        { id: 2, name: "应收" }
      ],
      // 调整账单
      isViewAdjustBill: false,
      editAdjustBillLoading: false,
      //
      detailColumns: [
        {
          title: "操作人",
          dataIndex: "creator",
          key: "creator"
        },
        {
          title: "调整项目",
          dataIndex: "typeName",
          key: "typeName"
        },
        {
          title: "调整内容",
          dataIndex: "event",
          scopedSlots: { customRender: "event" },
          width: 400
        },
        {
          title: "调整原因",
          dataIndex: "remark",
          key: "remark"
        },
        {
          title: "调整时间",
          dataIndex: "time",
          scopedSlots: { customRender: "time" }
        }
      ],
      pageSize: 20,
      current: 1,
      //
      detailData: [],
      isViewAdjustDetail: false
    };
  },
  watch: {},
  created() {},
  computed: {},
  methods: {
    moment,
    // 调整账单
    adjustBill() {
      this.isViewAdjustBill = true;
      let arr = [];
      if (!this.downPaymentTable) {
        return;
      }
      this.downPaymentTable.forEach(item => {
        arr.push(Object.assign({}, item));
      });
      this.editObj = arr;
      this.editArray = [];
    },
    // cancelViewAdjustBill
    cancelViewAdjustBill() {
      this.isViewAdjustBill = false;
      this.editObj = {};
      this.editArray = [];
    },
    inputChange(id) {
      this.editArray.push(id);
      this.editArray = this.unique(this.editArray);
    },
    unique(arr) {
      var hash = [];
      for (var i = 0; i < arr.length; i++) {
        if (hash.indexOf(arr[i]) == -1) {
          hash.push(arr[i]);
        }
      }
      return hash;
    },
    // handleSaveAdjustBill
    handleSaveAdjustBill() {
      if (this.editArray.length <= 0) {
        this.$message.info(`无内容变更！`);
        return;
      }
      let arr = [];
      this.editArray.forEach(id => {
        this.editObj.forEach(item => {
          if (item.id == id) {
            arr.push(item);
          }
        });
      });
      // 数据验证
      if (!this.testInfo(arr)) {
        return;
      }
      // arr
      // /bill/updateDownPayBillCost
      axios({
        url:   this.$baseUrl + "/bill/updateDownPayBillCost",
        method: "POST",
        data: arr
      }).then(res => {
        if (res.data.returnCode == 200) {
          this.$message.info(`修改成功！`);
          this.initTable();
          this.cancelViewAdjustBill();
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    testInfo(arr) {
      for (var k = 0, length3 = arr.length; k < length3; k++) {
        if (arr[k].totalAmount * 1 < 0) {
          this.$message.info(arr[k].typeName + `有误！`);
          return;
        }
        if (!arr[k].remark) {
          this.$message.info(arr[k].typeName + `备注有误！`);
          return;
        }
      }
      return true;
    },
    // 查看调整记录
    viewAdjustmentRecords() {
      // /bill/getDownPayBillAdjustRecord?billId=xxxxx
      this.recordsLoading = true;
      this.getAdjustmentRecords();
    },
    getAdjustmentRecords(current, pageSize) {
      axios({
        url:   this.$baseUrl + "bill/getDownPayBillAdjustRecord",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          billId: this.downPaymentDetail.id
        }
      }).then(res => {
        if (res.data.returnCode == 200) {
          this.isViewAdjustDetail = true;
          this.detailData = res.data.rows;
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.recordsLoading = false;
      });
    },
    changePageSize(val) {
      let current = val.current;
      let pageSize = val.pageSize;
      this.getAdjustmentRecords(current, pageSize);
    },
    // cancelAdjustDetail
    cancelAdjustDetail() {
      this.isViewAdjustDetail = false;
    }
  },
  filters: {}
};
</script>
<style lang="less" scoped>
.ffr {
  display: flex;
  justify-content: flex-end;
}
.nob {
  border-radius: 0 !important;
}
</style>
