<template>
  <a-spin :spinning="dataLoading">
    <div>
      <div style="margin: -45px -24px 0px">
        <a-card :bordered="false">
          <DescriptionList>
            <div class="table-operator">
              <div class="detailTop-edit" style="width: 90%">
                <img :src="tenant" class="icon" />
                <span class="code-margin">{{ detail.contractCode }}</span>
                <div class="close">
                  {{ changeStatus(detail.contractStatus) }}
                </div>
                <div class="edit blueColor">
                  <a v-if="canEditManageFee" @click="handleEditManageFee"
                    >设置管理费模式</a
                  >
                  <a v-if="canEditRent" @click="handleEditRent">设置基础服务费</a>
                  <a v-if="canChangeUtilityPrepayMode" @click="showChangeUtilityPrepayMode">开通水电预付模式</a>
                </div>
              </div>
              <div class="ffr">
                <a-popconfirm
                  placement="bottomRight"
                  title="确认同步到CRM吗？"
                  @confirm="syncToCRM"
                >
                  <a-button
                    style="margin-right: 16px"
                    v-if="syncContract"
                    :loading="syncingCRM"
                    >同步到CRM</a-button
                  >
                </a-popconfirm>
                <a-popconfirm
                    placement="bottomRight"
                    title="确认同步CSS吗？"
                    @confirm="syncToCSS"
                >
                  <a-button
                      style="margin-right: 16px"
                      v-if="syncContract"
                      :loading="syncingCSS"
                  >同步到CSS</a-button
                  >
                </a-popconfirm>
                <a-button
                  style="margin-right: 16px"
                  @click="confirmVoidContract"
                  type="primary"
                  v-if="detail.canCancel && canCancelContract"
                  >作废合同</a-button
                >
              </div>
              <!-- <br /> -->
            </div>
            <div class="detailStyle" style="margin-left: 8px">
              <Description term="所属品牌" class="first">
                <label>{{ detail.brandName }}</label>
              </Description>
              <Description term="客户名称" class="first">
                <label>{{ detail.tenantName }}</label>
              </Description>
              <Description term="店铺名称" class="first">
                <label>{{ detail.storeName }}</label>
              </Description>
              <Description term="档口编号" class="first">
                <label>{{ detail.signKitchen }}</label>
              </Description>
              <Description term="合同起租日期">
                <label>{{ detail.startDateLg }}</label>
              </Description>
              <Description term="合同到期日期">
                <label>{{ detail.endDateLg }}</label>
              </Description>
              <Description term="实际入驻日">
                <label>{{ detail.actualOccupancyDateLg }}</label>
              </Description>
              <Description term="计租开始日">
                <label>{{ detail.actualRentalDateLg }}</label>
              </Description>
              <Description term="实际离场日">
                <label>{{ detail.actualDepartureDateLg }}</label>
              </Description>
              <Description term="水电付费模式">
                <label>{{ getPrepayModeName(detail.utilityPrepayMode) }}</label>
              </Description>
              <Description v-if="utilityPrepayMode == 2" term="水电预付开始日期">
                <label>{{ changeTimeStyle(detail.utilityPrepayStartDateLg) }}</label>
              </Description>
              <Description v-if="utilityPrepayMode == 2" term="水电预付结束日期">
                <label>暂无</label>
              </Description>
              <Description term="是否为自营商户">
                <label>
                  {{
                    detail.isSelfOperated ? (detail.isSelfOperated == "N" ? "否" : "是") : ""
                  }}
                </label>
              </Description>
            </div>
          </DescriptionList>
        </a-card>
      </div>
      <div style="margin-bottom: 24px; margin: -24px -24px 24px">
        <a-card
          style="width: 100%"
          :bordered="false"
          :tabList="tabListNoTitle"
          :activeTabKey="noTitleKey"
          @tabChange="(key) => onTabChange(key, 'noTitleKey')"
        >
          <a-card
            v-if="noTitleKey === 'baseDetail'"
            :bordered="false"
            style="margin-left: -16px"
          >
            <DescriptionList title="签约信息" size="large">
              <div style="margin-top: 16px">
                <Description term="客户签约主体">
                  <label>{{ detail.signEntity }}</label>
                </Description>
                <Description term="合同类型">
                  <label>
                    {{ changeconType(detail.type) }}
                  </label>
                </Description>
                <Description term="合同年限">
                  <label>{{ detail.yearLimit }}</label>
                </Description>
                <Description term="合同预定日期">
                  <label>
                    {{ detail.contractReservedDateLg }}
                  </label>
                </Description>
                <Description term="基础服务费原价">
                  <label>{{ detail.monthRent }}</label>
                </Description>
                <Description term="基础服务费最低折扣价">
                  <label>{{ detail.mrMinDiscount }}</label>
                </Description>
                <Description term="基础服务费实际折扣率">
                  <label>{{ detail.mrActualDisRate }}%</label>
                </Description>
                <Description term="基础服务费付款周期">
                  <label>{{ detail.rentPaymentMonth }}</label>
                </Description>
                <Description term="履约保证金收取形式">
                  <label>
                    {{ changedepositType(detail.depositType) }}
                  </label>
                </Description>
                <Description term="履约保证金固定金额">
                  <label>{{ detail.depositAmount }}</label>
                </Description>
                <Description term="履约保证金周期">
                  <label>{{ detail.depositPeriod }}</label>
                </Description>
                <Description term="入会费原价">
                  <label>{{ detail.entryAmount }}</label>
                </Description>
                <Description term="实际入会费">
                  <label>{{ detail.actualEntryAmount }}</label>
                </Description>
                <Description term="入会费最低折扣价">
                  <label>{{ detail.entryMinDiscount }}</label>
                </Description>
                <Description term="入会费实际折扣率">
                  <label>{{ detail.entryActualDisRate }}</label>
                </Description>
                <Description term="管理费收取形式">
                  <label>{{ detail.manageFeeTypeName }}</label>
                </Description>
                <Description term="管理费固定金额">
                  <label>{{ detail.manageFee }}</label>
                </Description>
                <Description term="燃气改造费">
                  <label>{{ detail.gasReformFee }}</label>
                </Description>
                <Description term="燃气押金">
                  <label>{{ detail.gasDeposit }}</label>
                </Description>
                <Description term="质量保证金">
                  <label>{{ detail.foodSafeFee }}</label>
                </Description>
                <Description term="流水押金">
                  <label>{{ detail.waterDeposit }}</label>
                </Description>
                <Description term="堂食管理费">
                  <label>
                    {{ detail.foodcourtManagementFee }}
                  </label>
                </Description>
                <Description term="基础电费">
                  <label>{{ detail.eleManagementFee }}</label>
                </Description>
                <Description term="其他费用">
                  <label>{{ detail.otherFee }}</label>
                </Description>
                <Description term="首笔回款总金额">
                  <label>{{ detail.firstBackTotalFee }}</label>
                </Description>
                <Description term="定金">
                  <label>{{ detail.frontFee }}</label>
                </Description>
                <Description term="入会费可退方式">
                  <label>
                    {{ changeentryRefundType(detail.entryRefundType) }}
                  </label>
                </Description>
                <Description term="销客回款凭证">
                  <template>
                    <a @click="contractDeposit(detail)">
                      {{
                        !!detail.contractDepositList &&
                        detail.contractDepositList.length >= 1
                          ? "查看凭证"
                          : ""
                      }}
                    </a>
                    <a-modal
                      title="下载"
                      :visible="previewVisible"
                      :footer="null"
                      @cancel="handleCancel"
                    >
                      <div
                        style="line-height: 2; margin-left: 5%"
                        v-for="(item, index) in detail.contractDepositList"
                        :key="index"
                      >
                        <a :href="item ? item : null">
                          {{ "附件(" + index + ")" + contractTitle[index] }} </a
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a :href="item ? item : null">
                          <a-icon type="download" />
                        </a>
                      </div>
                    </a-modal>
                  </template>
                </Description>
                <Description term="入会费可退金额">
                  <label>{{ detail.entryRefundFee }}</label>
                </Description>
                <Description term="定金凭证编号">
                  <label>{{ detail.frontFeeProof }}</label>
                </Description>
                <Description term="免租期起始日">
                  <label>
                    {{ detail.rentFreeStartDateLg }}
                  </label>
                </Description>
                <Description term="免租期结束日">
                  <label>{{ detail.rentFreeEndDateLg }}</label>
                </Description>
                <Description term="餐饮流水是否走公司账户">
                  <label>
                    {{
                      detail.gmvIn ? (detail.gmvIn == "N" ? "否" : "是") : ""
                    }}
                  </label>
                </Description>
                <Description term="扣点">
                  <label>{{ detail.pointDeduction }}</label>
                </Description>
                <Description term="销客签约合同扫描件">
                  <template>
                    <a @click="previewattach(detail)">
                      {{
                        !!detail.attachmentList &&
                        detail.attachmentList.length >= 1
                          ? "查看凭证"
                          : ""
                      }}
                    </a>
                    <a-modal
                      title="下载"
                      :visible="previewVisibleattach"
                      :footer="null"
                      @cancel="handleCancel"
                    >
                      <div
                        style="line-height: 2; margin-left: 5%"
                        v-for="(item, index) in detail.attachmentList"
                        :key="index"
                      >
                        <a :href="item ? item : null">
                          {{
                            "附件(" + index + ")" + attachmentTitle[index]
                          }} </a
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a :href="item ? item : null">
                          <a-icon type="download" />
                        </a>
                      </div>
                    </a-modal>
                  </template>
                </Description>
                <Description term="证照注销保证金">
                  <label>{{ detail.cardCancelDeposit }}</label>
                </Description>
                <Description term="办证费用">
                  <label>{{ detail.cardRegFee }}</label>
                </Description>
                <Description term="原合同编号">
                    <label>{{ detail.sourceCode }}</label>
                </Description>
                <Description term="备注">
                  <label>{{ detail.remark }}</label>
                </Description>
              </div>
            </DescriptionList>
            <a-divider />
            <DescriptionList title="租金信息" size="large" class="marginB24">
              <div style="margin-top: 16px">
                <Description term="基础服务费">
                  <label>{{ detail.lessorActualRent }}</label>
                </Description>
                <Description term="场地使用费">
                  <label>{{ detail.siteUseServiceFee }}</label>
                </Description>
                <Description term="付款周期">
                  <label>{{ detail.rentPaymentMonth }}</label>
                </Description>
              </div>
            </DescriptionList>
            <a-divider />
            <DescriptionList title="合同文本" size="large">
              <div style="margin-top: 16px">
                <label>
                  <a
                    v-if="checkChildrenURLType(contractFileList) === 'img'"
                    @click="handlePreview(contractFileList)"
                    style="margin-left: 16px"
                    >[查看]</a
                  >
                </label>
                <label
                  v-for="(item, index) in contractFileList"
                  v-bind:key="index"
                >
                  <a
                    v-if="item.type != 'img'"
                    style="margin-left: 16px"
                    target="_blank"
                    :href="item.url"
                    :download="item.url"
                  >【{{ item.url | urlFileName }}】合同文本</a>
                </label>
              </div>
              <div class="contractBtnBox">
                <div
                  v-if="detail.canUpload && canUploadContract"
                  style="display: flex"
                >
                  <a-upload
                    accept=".pdf, .doc, .docx, .dot, .dotx, .docm, .dotm"
                    withCredentials
                    :action="this.uploadUrl()"
                    name="multipartFile"
                    @change="uploadCSV"
                    style="margin: 24px"
                  >
                    <a-button
                      style="margin-left: 8px; width: 120px"
                      type="primary"
                      >上传新合同</a-button
                    >
                  </a-upload>
                  <a-popconfirm
                    placement="topLeft"
                    title="确认锁定合同么？"
                    @confirm="lockContract"
                    okText="Yes"
                    cancelText="No"
                  >
                    <a-button
                      type="primary"
                      style="margin: 24px; width: 120px"
                      :disabled="lockContractButtonDisabled"
                      >锁定合同</a-button
                    >
                  </a-popconfirm>
                </div>
                <div
                  v-if="detail.canUploadSign && canUploadSign"
                  style="display: flex"
                >
                  <a-upload
                    accept=".pdf"
                    withCredentials
                    :multiple="false"
                    :action="this.uploadUrl()"
                    name="multipartFile"
                    @change="uploadPDF"
                    style="margin: 24px"
                  >
                    <a-button
                      style="margin-left: 8px; width: 120px"
                      type="primary"
                      >上传签约文档</a-button
                    >
                  </a-upload>
                  <a-popconfirm
                    placement="topLeft"
                    title="确认锁定签约文档么？"
                    @confirm="lockContractSign"
                    okText="Yes"
                    cancelText="No"
                  >
                    <a-button
                      :disabled="lockContractButtonDisabled"
                      type="primary"
                      style="margin: 24px; width: 120px"
                      >锁定签约文档</a-button
                    >
                  </a-popconfirm>
                </div>
              </div>
            </DescriptionList>
            <a-divider />
            <DescriptionList title="工程条件" size="large" class="marginB24">
              <div style="margin-top: 16px">
                <Description term="档口电量">
                  <label>{{ detail.elecNum }}</label>
                </Description>
                <Description term="燃气">
                  <label>{{ detail.gasNum }}</label>
                </Description>
                <Description term="档口排风量">
                  <label>{{ detail.airNum }}</label>
                </Description>
              </div>
            </DescriptionList>
            <a-divider />
            <DescriptionList title="改造要求" size="large" class="marginB24">
              <div style="margin-top: 16px">
                <Description term="是否框架改造">
                  <label>
                    {{
                      detail.isFrameReform
                        ? detail.isFrameReform == "N"
                          ? "否"
                          : "是"
                        : ""
                    }}
                  </label>
                </Description>
                <Description term="烟囱改造">
                  <label>
                    {{
                      detail.isSmokeReform
                        ? detail.isSmokeReform == "N"
                          ? "否"
                          : "是"
                        : ""
                    }}
                  </label>
                </Description>
                <Description term="电量增容">
                  <label>
                    {{
                      detail.isElecIncrease
                        ? detail.isElecIncrease == "N"
                          ? "否"
                          : "是"
                        : ""
                    }}
                  </label>
                </Description>
                <Description term="墙体结构">
                  <label>
                    {{
                      detail.isWallReform
                        ? detail.isWallReform == "N"
                          ? "否"
                          : "是"
                        : ""
                    }}
                  </label>
                </Description>
                <Description term="基础水电改造">
                  <label>{{ detail.isWaterElecReform }}</label>
                </Description>
                <Description term="工程改造费">
                  <label>{{ detail.engineReformFee }}</label>
                </Description>
                <Description term="工程清单">
                  <template>
                    <a @click="engineDetailUrlxlsx(detail)">
                      {{
                        !!detail.engineDetailUrlList &&
                        detail.engineDetailUrlList.length >= 1
                          ? "查看凭证"
                          : ""
                      }}
                    </a>
                    <a-modal
                      title="下载"
                      :visible="previewVisibleenginexlsx"
                      :footer="null"
                      @cancel="handleCancel"
                    >
                      <div
                        style="line-height: 2; margin-left: 5%"
                        v-for="(item, index) in detail.engineDetailUrlList"
                        :key="index"
                      >
                        <a :href="item ? item : null">
                          {{
                            "附件(" + index + ")" + engineDetailTitle[index]
                          }} </a
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a :href="item ? item : null">
                          <a-icon type="download" />
                        </a>
                      </div>
                    </a-modal>
                  </template>
                </Description>
                <Description term="设备清单">
                  <template>
                    <a @click="deviceDetailUrlxlsx(detail)">
                      {{
                        !!detail.deviceDetailUrlList &&
                        detail.deviceDetailUrlList.length >= 1
                          ? "查看凭证"
                          : ""
                      }}
                    </a>
                    <a-modal
                      title="下载"
                      :visible="previewVisibledevicexlsx"
                      :footer="null"
                      @cancel="handleCancel"
                    >
                      <div
                        style="line-height: 2; margin-left: 5%"
                        v-for="(item, index) in detail.deviceDetailUrlList"
                        :key="index"
                      >
                        <a :href="item ? item : null">
                          {{
                            "附件(" + index + ")" + deviceDetailTitle[index]
                          }} </a
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a :href="item ? item : null">
                          <a-icon type="download" />
                        </a>
                      </div>
                    </a-modal>
                  </template>
                </Description>
              </div>
            </DescriptionList>
            <a-divider />
            <DescriptionList title="折扣信息" size="large" class="marginB24">
              <div style="margin-top: 16px">
                <Description term="入会费可退方式">
                  <label>
                    {{
                      contractDiscount.entryfeeRefundRequirement !== 2
                        ? contractDiscount.entryfeeRefundRequirementTxt
                        : contractDiscount.entryfeeRefundOther
                    }}
                  </label>
                </Description>
                <Description term="入会费可退金额">
                  <label> {{ contractDiscount.entryfeeRefundAmount }} </label>
                </Description>
                <Description term="基础服务费折扣前X月">
                  <label> {{ contractDiscount.rentCondition }} </label>
                </Description>
                <Description term="基础服务费折扣率 %">
                  <label>
                    {{
                      contractDiscount.rentDiscount != null
                        ? `${contractDiscount.rentDiscount}%`
                        : ""
                    }}
                  </label>
                </Description>
                <Description term="开业后X月">
                  <label> {{ contractDiscount.rentFreeCondition1 }} </label>
                </Description>
                <Description term="免租X天">
                  <label> {{ contractDiscount.rentFreeDays1 }} </label>
                </Description>
                <Description term="开业后X月-1">
                  <label> {{ contractDiscount.rentFreeCondition2 }} </label>
                </Description>
                <Description term="免租X天-1">
                  <label> {{ contractDiscount.rentFreeDays2 }} </label>
                </Description>
                <Description term="开业后X月-2">
                  <label> {{ contractDiscount.rentFreeCondition3 }} </label>
                </Description>
                <Description term="免租X天-2">
                  <label> {{ contractDiscount.rentFreeDays3 }} </label>
                </Description>
                <Description term="开业后X月-3">
                  <label> {{ contractDiscount.rentFreeCondition4 }} </label>
                </Description>
                <Description term="免租X天-3">
                  <label> {{ contractDiscount.rentFreeDays4 }} </label>
                </Description>
              </div>
            </DescriptionList>
            <a-divider />
            <DescriptionList title="运营信息" size="large" class="marginB24">
              <div style="margin-top: 16px">
                <Description term="是否有餐饮经验">
                  <label>
                    {{
                      contractOperation.experience
                        ? contractOperation.experience == "N"
                          ? "否"
                          : "是"
                        : ""
                    }}
                  </label>
                </Description>
                <Description term="是否经营堂食">
                  <label>
                    {{
                      contractOperation.dineIn
                        ? contractOperation.dineIn == "N"
                          ? "否"
                          : "是"
                        : ""
                    }}
                  </label>
                </Description>
                <Description term="饿了么店铺数">
                  <label> {{ contractOperation.elemeStore }} </label>
                </Description>
                <Description term="美团店铺数">
                  <label> {{ contractOperation.meituanStore }} </label>
                </Description>
                <Description term="大众点评店铺数">
                  <label> {{ contractOperation.dianpingStore }} </label>
                </Description>
                <Description term="大众点评最高评分">
                  <label> {{ contractOperation.dianpingScore }} </label>
                </Description>
                <Description term="商户类型">
                  <label> {{ contractOperation.tenantTypeTxt }} </label>
                </Description>
              </div>
            </DescriptionList>
            <a-divider />
          </a-card>
          <a-card
            v-if="noTitleKey === 'downPayment'"
            :bordered="false"
            style="margin-left: -16px"
          >
            <DownPaymentDetail
              :downPaymentDetail="this.downPaymentDetail"
              :downPaymentTable="this.downPaymentDetail.billCostList || []"
              :initTable="getDownPaymentDetail"
              :isCanEdit="this.canEditBill"
            ></DownPaymentDetail>
          </a-card>
        </a-card>
      </div>
    </div>
    <!-- 弹窗 -->
    <!-- 设置管理费模式 -->
    <a-modal
      title="设置费用"
      :visible="editManageFee"
      :confirmLoading="manageFeeLoading"
      @ok="handleSaveEditManageFee"
      @cancel="handleCancelEditManageFee"
      destroyOnClose
    >
      <a-row :gutter="30" style="line-height: 4">
        <a-col :md="24" :sm="24">
          <span>管理费收取模式：</span>
          <a-select
            style="width: 175px; margin-left: 0.5em"
            v-model="manageFeeObj.manageFeeType"
            placeholder="请选择"
          >
            <a-select-option v-for="item in manageFeeTypeList" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :md="24" :sm="24" v-show="manageFeeObj.manageFeeType == 1">
          <span>固定管理费：</span>
          <a-input
            type="text"
            style="width: 200px"
            placeholder="请输入"
            v-model="manageFeeObj.manageFee"
          />
        </a-col>
        <a-col :md="24" :sm="24">
          <span>堂食管理费：</span>
          <a-input
            type="text"
            style="width: 200px"
            placeholder="请输入"
            v-model="manageFeeObj.foodcourtManagementFee"
          />
        </a-col>
        <a-col :md="24" :sm="24">
          <span>基础电费：</span>
          <a-input
            type="text"
            style="width: 200px"
            placeholder="请输入"
            v-model="manageFeeObj.eleManagementFee"
          />
        </a-col>
      </a-row>
    </a-modal>
    <!-- 设置租金 -->
    <a-modal
      title="设置基础服务费"
      :visible="editRent"
      :confirmLoading="rentLoading"
      @ok="handleSaveEditRent"
      @cancel="handleCancelEditRent"
      destroyOnClose
    >
      <a-row :gutter="30" style="line-height: 4">
        <a-col :md="24" :sm="24">
          <span>基础服务费金额：</span>
          <a-input
            type="text"
            style="width: 200px"
            placeholder="请输入"
            v-model="rentObj.lessorActualRent"
          />
        </a-col>
        <a-col :md="24" :sm="24">
          <span>场地使用费金额：</span>
          <a-input
              type="text"
              style="width: 200px"
              placeholder="请输入"
              v-model="rentObj.siteUseServiceFee"
          />
        </a-col>
        <a-col :md="24" :sm="24">
          <span>基础服务费付款周期：</span>
          <a-input
            type="text"
            style="width: 200px"
            placeholder="请输入"
            v-model="rentObj.rentPaymentMonth"
          />
        </a-col>
      </a-row>
    </a-modal>
    <!-- 图片弹窗 -->
    <a-modal
      :visible="previewVisibleImg"
      :width="'50vw'"
      :footer="null"
      @cancel="handleCancelImg"
    >
      <div
        v-for="(url, index) in previewImageUrls"
        v-bind:key="`img_${index}`"
        class="image-wrapper"
      >
        <img style="width: 100%" :src="url" />
      </div>
    </a-modal>
    <!-- 开通水电预付模式 -->
    <a-modal
      title="开通水电预付模式"
      :visible="changeUtilityPrepayMode"
      :confirmLoading="changeUtilityPrepayModeLoading"
      @ok="handleConfirmChangeUtilityPrepayMode"
      @cancel="handleCancelChangeUtilityPrepayMode"
      destroyOnClose
    >
      <a-row :gutter="30" style="line-height: 4">
        <a-col :md="24" :sm="24">
          <span>水电预付开始日期：</span>
          <a-date-picker
            format="YYYY-MM-DD"
            v-model="utilityPrepayStartDate"
            :disabled-date="disabledDateForUtilityPrepay"
          />
        </a-col>
      </a-row>
    </a-modal>
  </a-spin>
</template>

<script>
import DescriptionList from "@/components/DescriptionList";
import tenant from "@/assets/tenant.png";
import moment from "moment";
import Bus from "@/bus";
import { mapGetters } from "vuex";
import { GET_USER_CITIES } from "@/store/modules/actions.type";
import { hasPermissions } from "../../../utils/auth";
import { OrganizationActions } from "../../../utils/actions";
import { Modal } from "ant-design-vue";
import DownPaymentDetail from "./components/DownPaymentDetail";

const { Description } = DescriptionList;
export default {
  name: "contractDetails",
  components: {
    DescriptionList,
    Description,
    DownPaymentDetail,
  },
  data() {
    return {
      // loading
      dataLoading: false,
      contractDetailsId: this.$route.query.contractDetailsId,
      tenant,
      detail: {},
      // 合同列表
      contractFileList: [],
      tabListNoTitle: [
        {
          key: "baseDetail",
          tab: "基本信息",
        },
        {
          key: "downPayment",
          tab: "首期款账单",
        },
        // downPayment
      ],
      //合同状态
      contractStatus: [
        {
          code: 1,
          name: "预定中",
        },
        {
          code: 2,
          name: "已预订",
        },
        {
          code: 3,
          name: "预定作废",
        },
        {
          code: 4,
          name: "已签约回款",
        },
        {
          code: 5,
          name: "已退租",
        },
        {
          code: 6,
          name: "已驳回",
        },
        {
          code: 7,
          name: "已续租",
        },
        {
          code: 8,
          name: "已签约未回款",
        },
        {
          code: 9,
          name: "待修改",
        },
        {
          code: 10,
          name: "待签约",
        },
        {
          code: 11,
          name: "未签约已回款",
        },
      ],
      //合同类型
      conTypeList: [
        { id: 1, name: "新签正式合同" },
        { id: 2, name: "新签预定合同" },
        { id: 3, name: "换铺" },
      ],
      // 履约保证金收取形式
      depositTypeList: [
        { id: 1, name: "固定金额" },
        { id: 2, name: "按X基础服务费收取" },
      ],
      //入会费可退方式
      entryRefundTypeList: [
        { id: 1, name: "满6个月后返还50%" },
        { id: 2, name: "其他" },
      ],
      noTitleKey: "baseDetail",
      contractTitle: [],
      attachmentTitle: [],
      engineDetailTitle: [],
      deviceDetailTitle: [],
      previewVisible: false,
      previewVisibleenginexlsx: false,
      previewVisibledevicexlsx: false,
      previewVisibleattach: false,
      // statusList
      manageFeeTypeList: [
        { id: 1, name: "固定金额" },
        { id: 2, name: "实际公摊" },
      ],
      // 设置管理费模式
      manageFeeObj: {},
      editManageFee: false,
      manageFeeLoading: false,
      // 设置设置租金
      rentObj: {},
      editRent: false,
      rentLoading: false,
      // 开通水电预付费模式
      changeUtilityPrepayMode: false, // whether to display the date setting modal dialog
      changeUtilityPrepayModeLoading: false, // whether to display the loading animation
      utilityPrepayStartDate: moment(), //defaults to today
      // 查看附件图片
      previewImageUrls: [],
      previewVisibleImg: false,
      // 首期款对象
      downPaymentDetail: {},

      // 折扣信息
      contractDiscount: {},
      // 经营信息
      contractOperation: {},
      // 是否正在同步合同到CRM
      syncingCRM: false,
      // 是否正在同步合同到CSS
      syncingCSS: false,
      // 水电付费模式
      utilityPrepayMode: null,
      utilityBalanceAccountId: null,
    };
  },
  created() {
    this.init();
    // this.getContractStatus();
    Bus.$on("getTarget", (target) => {
      this.$router.push({
        path: "/role/RoleList",
      });
    });
  },
  activated() {
    this.init();
  },
  methods: {
    moment,
    init() {
      this.dataLoading = true;
      this.getContractBasedetail();
      this.getDownPaymentDetail();
      setTimeout(() => {
        this.dataLoading = false;
      }, 0);
    },
    // getcontractBasedetail
    getContractBasedetail() {
      axios({
        url: this.$baseUrl + "contract/query/detail",
        method: "GET",
        params: {
          contractId: this.$route.query.contractDetailsId,
        },
      }).then((res) => {
        if (res.data.success) {
          res.data.obj.endDateLg = this.changeTimeStyle(res.data.obj.endDateLg);
          res.data.obj.startDateLg = this.changeTimeStyle(
            res.data.obj.startDateLg
          );
          res.data.obj.rentFreeEndDateLg = this.changeTimeStyle(
            res.data.obj.rentFreeEndDateLg
          );
          res.data.obj.rentFreeStartDateLg = this.changeTimeStyle(
            res.data.obj.rentFreeStartDateLg
          );
          res.data.obj.contractReservedDateLg = this.changeTimeStyle(
            res.data.obj.contractReservedDateLg
          );
          res.data.obj.actualOccupancyDateLg = this.changeTimeStyle(
            res.data.obj.actualOccupancyDateLg
          );
          res.data.obj.actualRentalDateLg = this.changeTimeStyle(
            res.data.obj.actualRentalDateLg
          );
          res.data.obj.actualDepartureDateLg = this.changeTimeStyle(
            res.data.obj.actualDepartureDateLg
          );
          this.manageFeeTypeList.map((i) => {
            if (i.id == res.data.obj.manageFeeType) {
              res.data.obj.manageFeeTypeName = i.name;
            }
          });
          this.detail = res.data.obj;
          // 获取类型
          let contractFileList = [];
          if (!this.detail.contractFileList) {
            this.contractFileList = contractFileList;
            this.dataLoading = false;
            return;
          }
          this.detail.contractFileList.forEach((item) => {
            let ii = {};
            ii.type = this.checkURLType(item);
            ii.url = item;
            contractFileList.push(ii);
          });
          this.contractFileList = contractFileList;
          // 折扣信息
          this.contractDiscount = res.data.obj.contractDiscount;
          // 经营信息
          this.contractOperation = res.data.obj.contractOperation;
          // 付费模式
          this.utilityPrepayMode = res.data.obj.utilityPrepayMode;
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.dataLoading = false;
      });
    },
    // 点击查看附件函数
    getContractFile(urls) {
      if (!urls) {
        return [];
      }
      let arr = [];
      urls.forEach((item) => {
        let obj = {};
        obj.type = this.checkURLType(item);
        obj.url = item;
        arr.push(obj);
      });
      return arr;
    },
    // 判断图片
    checkURLType(url) {
      if (/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(url)) {
        return "img";
      } else {
        return "other";
      }
    },
    // 判断集合中的item是否是图片（根据第一个元素），可能返回img, other, null
    checkChildrenURLType(urls) {
      if (!urls || urls.length === 0) {
        return null;
      } else {
        return this.checkURLType(urls[0].url);
      }
    },
    contractDeposit(val) {
      this.detail.contractDepositList.forEach((item) => {
        let title;
        let num;
        item.toString();
        title = item.slice(-6);
        num = title.indexOf(".");
        title = title.slice(num);
        this.contractTitle.push(title);
      });
      this.previewVisible = true;
    },
    engineDetailUrlxlsx() {
      this.detail.engineDetailUrlList.forEach((item) => {
        let title;
        let num;
        if (item) {
          item.toString();
          title = item.slice(-6);
          num = title.indexOf(".");
          title = title.slice(num);
          this.engineDetailTitle.push(title);
          this.previewVisibleenginexlsx = true;
        }
      });
    },
    // getDownPaymentDetail
    getDownPaymentDetail() {
      axios({
        url: this.$baseUrl + "bill/getDownPayBill",
        method: "GET",
        params: {
          contractId: this.$route.query.contractDetailsId,
        },
      }).then((res) => {
        if (res.data.returnCode == 200) {
          this.downPaymentDetail = res.data.obj || {};
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    deviceDetailUrlxlsx() {
      this.detail.deviceDetailUrlList.forEach((item) => {
        let title;
        let num;
        item.toString();
        title = item.slice(-6);
        num = title.indexOf(".");
        title = title.slice(num);
        this.deviceDetailTitle.push(title);
        this.previewVisibledevicexlsx = true;
      });
    },
    previewattach() {
      this.detail.attachmentList.forEach((item) => {
        let title;
        let num;
        item.toString();
        title = item.slice(-6);
        num = title.indexOf(".");
        title = title.slice(num);
        this.attachmentTitle.push(title);
        this.previewVisibleattach = true;
      });
    },
    // 确认作废合同
    confirmVoidContract() {
      let that = this;
      Modal.confirm({
        title: "请注意！",
        content:
          "一旦合同作废，档口将立刻释放，如已经支付首期款账单将转移至商户余额账户内，该操作无法恢复，确认继续吗？",
        onOk() {
          that.voidContract();
        },
        onCancel() {},
      });
    },
    // 作废合同
    voidContract() {
      axios({
        url: this.$baseUrl + "contract/cancel",
        method: "GET",
        params: {
          contractId: this.contractDetailsId,
        },
      }).then((res) => {
        if (res.data.returnCode == "200") {
          this.$message.info(`${res.data.returnMsg}`);
          setTimeout(() => {
            this.$router.push({
              path: "/role/contractList",
              query: {
                reset: true,
              },
            });
          }, 800);
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    handleCancel() {
      this.previewVisibleenginexlsx = false;
      this.previewVisibledevicexlsx = false;
      this.previewVisibleattach = false;
      this.previewVisible = false;
      this.contractTitle = [];
      this.attachmentTitle = [];
      this.deviceDetailTitle = [];
      this.engineDetailTitle = [];
    },
    //合同状态
    changeStatus(val) {
      let name = null;
      this.contractStatus.forEach((item) => {
        if (val == item.code) {
          name = item.name;
        }
      });
      return name;
    },
    //合同类型
    changeconType(val) {
      let name = "";
      if (val) {
        this.conTypeList.forEach((item) => {
          if (item.id == val) {
            name = item.name;
          }
        });
        return name;
      } else {
        return name;
      }
    },
    // 履约保证金收取形式
    changedepositType(val) {
      let name = "";
      if (val) {
        if (val == 1) {
          name = "固定金额";
        } else if (val == 2) {
          name = "按" + this.detail.depositPeriod + "基础服务费收取";
        }
        return name;
      } else {
        return name;
      }
    },
    //入会费可退方式
    changeentryRefundType(val) {
      let name = "";
      if (val) {
        this.entryRefundTypeList.forEach((item) => {
          if (item.id == val) {
            name = item.name;
          }
        });
        return name;
      } else {
        return name;
      }
    },
    changeTimeStyle(time) {
      return time ? moment(time).format("YYYY-MM-DD") : "";
    },
    onTabChange(key, type) {
      this[type] = key;
    },
    //显示编辑管理费
    handleEditManageFee() {
      let obj = {};
      this.manageFeeObj = Object.assign(
        {},
        {
          id: this.detail.id,
          manageFeeType: this.detail.manageFeeType,
          manageFee: this.detail.manageFee,
          foodcourtManagementFee: this.detail.foodcourtManagementFee,
          eleManagementFee: this.detail.eleManagementFee,
        }
      );
      this.editManageFee = true;
    },
    // 取消显示编辑管理费
    handleCancelEditManageFee() {
      this.manageFeeObj = {};
      this.editManageFee = false;
    },
    // 编辑管理费保存修改
    handleSaveEditManageFee() {
      // 验证
      if (!this.manageFeeObj.manageFeeType) {
        this.$message.error(`管理费收取模式有误！`);
        return;
      }
      if (
        this.manageFeeObj.manageFeeType == 1 &&
        (!(this.manageFeeObj.manageFee + "").length ||
          isNaN(this.manageFeeObj.manageFee * 1) ||
          this.manageFeeObj.manageFee * 1 < 0)
      ) {
        this.$message.error(`固定管理费有误！`);
        return;
      }
      if (
        !(this.manageFeeObj.foodcourtManagementFee + "").length ||
        isNaN(this.manageFeeObj.foodcourtManagementFee * 1) ||
        this.manageFeeObj.foodcourtManagementFee * 1 < 0
      ) {
        this.$message.error(`堂食管理费有误！`);
        return;
      }
      if (
        !(this.manageFeeObj.eleManagementFee + "").length ||
        isNaN(this.manageFeeObj.eleManagementFee * 1) ||
        this.manageFeeObj.eleManagementFee * 1 < 0
      ) {
        this.$message.error(`基础电费有误！`);
        return;
      }
      this.manageFeeLoading = true;

      if (this.manageFeeObj.manageFeeType == 2) {
        this.manageFeeObj.manageFee = "";
      }
      // POST /fms_css/contract/modify 修改合同信息
      axios({
        url: this.$baseUrl + "contract/modify",
        method: "POST",
        data: {
          ...this.manageFeeObj,
        },
      }).then((res) => {
        if (res.data.returnCode == "200") {
          this.editManageFee = false;
          this.manageFeeObj = {};
          this.init();
          this.$message.info(`${res.data.returnMsg}`);
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.manageFeeLoading = false;
      });
    },
    //显示编辑租金
    handleEditRent() {
      let obj = {};
      this.rentObj = Object.assign(
        {},
        {
          id: this.detail.id,
          lessorActualRent: this.detail.lessorActualRent,
          siteUseServiceFee: this.detail.siteUseServiceFee,
          rentPaymentMonth: this.detail.rentPaymentMonth,
        }
      );
      this.editRent = true;
    },
    // 取消显示编辑租金
    handleCancelEditRent() {
      this.rentObj = {};
      this.editRent = false;
    },
    // 编辑管理费保存修改
    handleSaveEditRent() {
      // 验证
      if (
        !(this.rentObj.lessorActualRent + "").length ||
        isNaN(this.rentObj.lessorActualRent * 1) ||
        this.rentObj.lessorActualRent * 1 < 0
      ) {
        this.$message.error(`基础服务费金额有误！`);
        return;
      }
      if (
        !(this.rentObj.rentPaymentMonth + "").length ||
        isNaN(this.rentObj.rentPaymentMonth * 1) ||
        this.rentObj.rentPaymentMonth * 1 < 0
      ) {
        this.$message.error(`基础服务费付款周期不正确！`);
        return;
      }
      this.rentLoading = true;
      // POST /fms_css/contract/modifyRent 修改合同租金及付款周期
      axios({
        url: this.$baseUrl + "contract/modifyRent",
        method: "POST",
        data: {
          ...this.rentObj,
        },
      }).then((res) => {
        if (res.data.returnCode == "200") {
          this.editRent = false;
          this.rentObj = {};
          this.init();
          this.$message.info(`${res.data.returnMsg}`);
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.rentLoading = false;
      });
    },
    // 锁定合同
    lockContract() {
      // POST /fms_css/contract/lockContract 锁定合同
      let data = {};
      data.contractId = this.contractDetailsId;
      axios({
        url: this.$baseUrl + "contract/lockContract",
        method: "GET",
        params: data,
      })
        .then((res) => {
          if (res.data.success) {
            this.$message.info(`${res.data.returnMsg}`);
            this.init();
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
          this.dataLoading = false;
        })
        .catch((error) => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    // 上传url
    uploadUrl() {
      return this.$baseUrl + "upload/ossFile";
    },
    // CSV上传
    uploadCSV({ fileList }) {
      this.dataLoading = true;
      if (fileList.length >= 1) {
        fileList.forEach((element, index) => {
          if (element.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
          if (element.response) {
            if (element.response.returnCode == "200") {
              this.afterUploadCSV(element);
              fileList.splice(index, 1);
            } else {
              this.$message.error("上传失败！");
              this.dataLoading = false;
              fileList.splice(index, 1);
              return;
            }
          }
        });
      }
    },
    // afterUploadCSV
    // POST /fms_css/contract/uploadContractFile 上传合同模版文件
    afterUploadCSV(info) {
      let data = {};
      data.filePath = info.response.obj;
      data.contractId = this.contractDetailsId;
      axios({
        url: this.$baseUrl + "contract/uploadContractFile",
        method: "POST",
        data: data,
      })
        .then((res) => {
          if (res.data.success) {
            this.$message.info(`${res.data.returnMsg}`);
            this.init();
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
          this.dataLoading = false;
        })
        .catch((error) => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    // 锁定签约文档
    lockContractSign() {
      axios({
        url: this.$baseUrl + "contract/lockSigned",
        method: "GET",
        params: {
          contractId: this.contractDetailsId,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.$message.info(`${res.data.returnMsg}`);
            this.init();
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
          this.dataLoading = false;
        })
        .catch((error) => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    // 签约文档上传
    uploadPDF({ fileList }) {
      this.dataLoading = true;
      if (fileList.length >= 1) {
        fileList.forEach((element, index) => {
          if (element.status == "error") {
            this.dataLoading = false;
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
          if (element.response) {
            if (element.response.returnCode == "200") {
              fileList.splice(index, 1);
              this.afterUploadPDF(element);
            } else {
              this.$message.error("上传失败！");
              fileList.splice(index, 1);
              return;
            }
          }
        });
      }
    },
    afterUploadPDF(info) {
      let data = {};
      data.filePath = info.response.obj;
      data.contractId = this.contractDetailsId;
      axios({
        url: this.$baseUrl + "contract/uploadSignedFile",
        method: "POST",
        data: data,
      })
        .then((res) => {
          if (res.data.success) {
            this.$message.info(`${res.data.returnMsg}`);
            this.init();
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
          this.dataLoading = false;
        })
        .catch((error) => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    // GET /fms_css/code/list 获取字典列表
    // 获取合同状态
    getContractStatus() {
      this.actionData = [];
      axios({
        url: this.$baseUrl + "code/list",
        method: "GET",
        params: {
          codeType: "contractStatus",
        },
      }).then((res) => {
        if (res.data.returnCode == 200) {
          this.contractStatus = res.data.rows;
        }
      });
    },
    // 预览
    handlePreview(fileList) {
      this.previewImageUrls = fileList.map((i) => i.url);
      this.previewVisibleImg = true;
    },
    // 预览取消
    handleCancelImg() {
      this.previewImageUrls = [];
      this.previewVisibleImg = false;
    },
    // 同步合同到CRM
    syncToCRM() {
      this.syncingCRM = true;
      this.$http
        .get(`contract/syncCrm?contractId=${this.contractDetailsId}`)
        .then((res) => {
          if (res.data.success) {
            this.$message.success("同步成功");
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
          this.syncingCRM = false;
        });
    },
    // 同步合同到CSS
    syncToCSS() {
      this.syncingCSS = true;
      this.$http
          .post(`contract/upsert?contractId=${this.contractDetailsId}`)
          .then((res) => {
            if (res.data.success) {
              this.$message.success("同步成功");
            } else {
              this.$message.error(`${res.data.returnMsg}`);
            }
            this.syncingCSS = false;
          });
    },
    showChangeUtilityPrepayMode() {
      this.changeUtilityPrepayMode = true;
    },
    disabledDateForUtilityPrepay(current) {
      return current && (current < moment().startOf('day') || current > moment().endOf('day'));
    },
    handleConfirmChangeUtilityPrepayMode() {
      if (this.utilityPrepayStartDate) {
        this.changeUtilityPrepayModeLoading = true;

        axios({
          url: this.$endpoints.OG_ACCOUNT_CREATE_UTILITY_BALANCE_ACCOUNT,
          method: "POST",
          data: {
            contractId: this.contractDetailsId,
          }
        }).then((res) => {
          if (res.data.account.id) {
            var utility_account_id = res.data.account.id;

            axios({
              url: this.$endpoints.OG_ACCOUNT_ENABLE_UTILITY_BALANCE_ACCOUNT,
              method: "GET",
              params: {
                account_id: utility_account_id,
                restart: false,
              }
            }).then((res) => {
              this.changeUtilityPrepayModeLoading = false;
              this.changeUtilityPrepayMode = false;

              if (res.data.isEnabled) {
                this.utilityBalanceAccountId = utility_account_id;
                this.utilityPrepayMode = 2;
                this.$message.success("创建水电预付费账号成功，开通水电预付费模式成功", 5);
              }
              else {
                this.$message.error("水电预付费账号创建成功，开通预付费模式失败", 5);
              }
            }).catch(error => {
                this.changeUtilityPrepayModeLoading = false;
                this.changeUtilityPrepayMode = false;

                this.$message.error("开通水电预付费模式错误，错误信息：" + error.response.data, 5);
            });

          }
          else {
            this.changeUtilityPrepayModeLoading = false;
            this.changeUtilityPrepayMode = false;

            this.$message.error("创建水电预付费账号失败", 5);
          }
        }).catch(error => {
          this.changeUtilityPrepayModeLoading = false;
          this.changeUtilityPrepayMode = false;

          this.$message.error("创建水电预付费账号错误，错误信息：" + error.response.data, 5);
        });
      }
      else {
        this.$message.error("请选择起始日期", 3);
      }
    },
    handleCancelChangeUtilityPrepayMode() {
      this.utilityPrepayStartDate = null;
      this.changeUtilityPrepayMode = false;
    },

    getPrepayModeName(mode) {
      if (mode == 1) {
        return '后付费';
      } else if (mode == 2) {
        return '预付费';
      } else {
        return '暂无';
      }
    },
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    canEditManageFee() {
      return hasPermissions(
        [OrganizationActions.CONTRACT_EDIT],
        this.authenticatedUser.actions
      );
    },
    canEditRent() {
      return hasPermissions(
        [OrganizationActions.CONTRACT_EDIT],
        this.authenticatedUser.actions
      );
    },
    canChangeUtilityPrepayMode() {
      return this.utilityPrepayMode != 2;
    },
    canCancelContract() {
      return hasPermissions(
        [OrganizationActions.CONTRACT_CANCEL],
        this.authenticatedUser.actions
      );
    },
    canEditBill() {
      return hasPermissions(
        [OrganizationActions.CONTRACT_BILL_EDIT],
        this.authenticatedUser.actions
      );
    },
    // 上传/锁定合同文件
    canUploadContract() {
      return hasPermissions(
        [OrganizationActions.CONTRACT_UPLOAD],
        this.authenticatedUser.actions
      );
    },
    // 上传/锁定签约文档
    canUploadSign() {
      return hasPermissions(
        [OrganizationActions.CONTRACT_SIGN_UPLOAD],
        this.authenticatedUser.actions
      );
    },
    syncContract() {
      return hasPermissions(
          [OrganizationActions.CONTRACT_SYNC],
          this.authenticatedUser.actions
      );
    },
    // 锁定合同按钮/锁定签约文档按钮的可用性
    lockContractButtonDisabled() {
      if (
        !this.detail.contractFileList ||
        this.detail.contractFileList.length === 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="less">
.contractBtnBox {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.contract a {
  color: #1890ff;
}
.ffr {
  display: flex;
  justify-content: flex-end;
}
.table-operator {
  display: flex;
  margin-bottom: 18px;
  button {
    margin-right: 8px;
  }
}
.marginB24 {
  margin-top: 16px;
  margin-bottom: 24px;
}
.edit {
  a {
    margin-left: 1.5em;
    font-size: 0.9em;
  }
  display: inline;
  font-size: 0.8em;
}
.image-modal {
  width: 600px;
}
.image-wrapper {
  display: flex;
  flex-direction: column;
}
.code-margin {
  margin: 0 10px;
}
</style>
